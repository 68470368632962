import React from 'react';

import { IconButton } from '@atlaskit/button/new';
import CrossIcon from '@atlaskit/icon/core/close';
import { token } from '@atlaskit/tokens';

interface CrossIconCloseButtonProps {
	label: React.ReactNode;
	onClose?: () => void;
}

export const CrossIconCloseButton: React.FC<CrossIconCloseButtonProps> = ({ onClose, label }) => {
	return (
		<IconButton
			icon={(iconProps) => <CrossIcon {...iconProps} color={token('color.icon.inverse')} />}
			appearance="subtle"
			label={label}
			onClick={onClose}
		/>
	);
};
